<template>
  <div :class="dwellPopupClass">
    <div class="xnib-dwell-popup">
      <div class="xnib-dwell-popup-body">
        <div class="xnib-dwell-close" @click="closeDwellPopup">
          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.3 0.71C12.91 0.32 12.28 0.32 11.89 0.71L6.99997 5.59L2.10997 0.699999C1.71997 0.309999 1.08997 0.309999 0.699971 0.699999C0.309971 1.09 0.309971 1.72 0.699971 2.11L5.58997 7L0.699971 11.89C0.309971 12.28 0.309971 12.91 0.699971 13.3C1.08997 13.69 1.71997 13.69 2.10997 13.3L6.99997 8.41L11.89 13.3C12.28 13.69 12.91 13.69 13.3 13.3C13.69 12.91 13.69 12.28 13.3 11.89L8.40997 7L13.3 2.11C13.68 1.73 13.68 1.09 13.3 0.71Z" fill="#668099"/>
          </svg>
        </div>
        <div class="xnib-dwell-text-container">
          <h3>{{headingText}}</h3>
          <p>{{text}}</p>
        </div>
        <div class="xnib-container show xnib-dwell-button-wrapper" v-if="widgetContent.primary_button_action == null && localTheme.button">
          <start-button @click="mainButtonClick" ref="button" :theme="localTheme.button" :white-label="whiteLabel" />
        </div>
        <button :style="primaryButtonStyle" class="xnib-info-button-primary" v-if="widgetContent.primary_button_action != null" @click="primaryClick" ref="button">
          <span class="text">{{widgetContent.primary_button_text}}</span>
        </button>
        <button :style="secondaryButtonStyle" class="xnib-info-button-secondary" v-if="widgetContent.secondary_button_action != null" @click="secondaryClick" ref="button">
          <span class="text">{{widgetContent.secondary_button_text}}</span>
        </button>

      </div>
      <svg class="xnib-dwell-logo" width="76" height="76" viewBox="0 0 76 76" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="showLogo">
        <path d="M40.206 0.421096L22.2161 3.61929C24.8432 18.5252 14.9059 32.7458 0 35.3729L3.19821 53.3628C5.82531 68.2687 20.0459 78.206 34.9518 75.5789L52.9417 72.3807C67.8476 69.7536 77.7849 55.533 75.1578 40.6271L71.9596 22.6372C69.3325 7.73128 55.1119 -2.206 40.206 0.421096Z" fill="url(#paint0_linear)"/>
        <path d="M24.506 53.0752C28.8547 52.3086 31.7585 48.1619 30.9919 43.8132C30.2254 39.4645 26.0786 36.5606 21.7299 37.3272C17.3813 38.0938 14.4774 42.2405 15.244 46.5892C16.0106 50.9379 20.1573 53.8418 24.506 53.0752Z" fill="white"/>
        <path d="M51.1713 59.131C48.3729 60.9585 45.2318 62.215 41.8623 62.7861C38.5499 63.3572 35.1803 63.3001 31.8679 62.5576C30.6115 62.2721 29.869 61.0728 30.1546 59.8163C30.4401 58.5599 31.6394 57.8174 32.8959 58.103C35.5801 58.7312 38.3214 58.7883 41.0627 58.3314C43.8041 57.8745 46.374 56.8465 48.6585 55.3617C49.7436 54.6763 51.1142 54.9619 51.7996 56.047C52.542 57.0179 52.2564 58.4457 51.1713 59.131Z" fill="white"/>
        <path d="M53.5128 47.8231C57.9286 47.8231 61.5084 44.2434 61.5084 39.8276C61.5084 35.4118 57.9286 31.8321 53.5128 31.8321C49.097 31.8321 45.5173 35.4118 45.5173 39.8276C45.5173 44.2434 49.097 47.8231 53.5128 47.8231Z" fill="white"/>
        <defs>
          <linearGradient id="paint0_linear" x1="37.7894" y1="3.9976" x2="37.7894" y2="73.7326" gradientUnits="userSpaceOnUse">
            <stop :stop-color="gradientEndColor"/>
            <stop offset="1" :stop-color="gradientStartColor"/>
          </linearGradient>
        </defs>
      </svg>
    </div>
  </div>
</template>

<script>
import StartButton from '@/components/StartButton.vue'
import Utils from '@/utils/utils'

export default {
  name: 'DwellPopup',
  components: { StartButton },
  props: {
    theme: {
      type: Object,
      required: false
    },
    widgetContent: {
      type: Object,
      required: true
    },
    whiteLabel: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      localTheme: {},
      visible: false
    }
  },
  computed: {
    headingText () {
      if (this.widgetContent.info_heading != null) {
        return this.widgetContent.info_heading
      } else if (this.localTheme.dwellTimePopup != null && this.localTheme.dwellTimePopup.headingText != null) {
        return this.localTheme.dwellTimePopup.headingText
      } else {
        return 'Still undecided?'
      }
    },
    text () {
      if (this.widgetContent.info_text_normal != null) {
        return this.widgetContent.info_text_normal
      } else if (this.localTheme.dwellTimePopup != null && this.localTheme.dwellTimePopup.text != null) {
        return this.localTheme.dwellTimePopup.text
      } else {
        return "Let's talk a deal: instant chatbot, no login needed, checkout as usual."
      }
    },
    dwellPopupClass () {
      var classes = ['xnib-dwell-container']
      if (this.visible) {
        classes.push('show')
      }
      if (this.whiteLabel) {
        classes.push('xnib-dwell-white-label')
      }
      return classes
    },
    primaryButtonStyle () {
      return {
        '--xnib-button-background-color': this.localTheme.button.backgroundColor,
        '--xnib-button-border-color': this.localTheme.button.backgroundColor,
        '--xnib-button-border-radius': this.localTheme.button.borderRadius,
        '--xnib-button-box-shadow': this.localTheme.button.boxShadow,
        '--xnib-button-height': this.localTheme.button.height,
        '--xnib-button-padding': this.localTheme.button.padding,
        '--xnib-button-transition': this.localTheme.button.transition,
        '--xnib-button-hover-color': this.localTheme.button.hover.color === 'auto' ? this.localTheme.button.color : this.localTheme.button.hover.color,
        '--xnib-button-hover-background-color': this.localTheme.button.hover.backgroundColor === 'auto' ? this.localTheme.button.backgroundColor : this.localTheme.button.hover.backgroundColor,
        '--xnib-button-hover-border-color': this.localTheme.button.hover.backgroundColor === 'auto' ? this.localTheme.button.backgroundColor : this.localTheme.button.hover.backgroundColor,
        '--xnib-button-hover-border-radius': this.localTheme.button.hover.borderRadius === 'auto' ? this.localTheme.borderRadius : this.localTheme.button.hover.borderRadius,
        '--xnib-button-hover-box-shadow': this.localTheme.button.hover.boxShadow === 'auto' ? this.localTheme.boxShadow : this.localTheme.button.hover.boxShadow,
        '--xnib-button-text-font-family': this.localTheme.button.fontFamily,
        '--xnib-button-text-font-weight': this.localTheme.button.fontWeight,
        '--xnib-button-text-font-style': this.localTheme.button.fontStyle,
        '--xnib-button-text-font-size': this.localTheme.button.fontSize,
        '--xnib-button-text-font-smoothing': this.localTheme.button.fontSmoothing,
        '--xnib-button-text-font-stretch': this.localTheme.button.fontStretch,
        '--xnib-button-text-color': this.localTheme.button.color,
        '--xnib-button-text-letter-spacing': this.localTheme.button.letterSpacing,
        '--xnib-button-text-text-transform': this.localTheme.button.textTransform
      }
    },
    secondaryButtonStyle () {
      return {
        '--xnib-button-background-color': this.localTheme.button.color,
        '--xnib-button-border-color': this.localTheme.button.backgroundColor,
        '--xnib-button-border-radius': this.localTheme.button.borderRadius,
        '--xnib-button-box-shadow': this.localTheme.button.boxShadow,
        '--xnib-button-height': this.localTheme.button.height,
        '--xnib-button-padding': this.localTheme.button.padding,
        '--xnib-button-transition': this.localTheme.button.transition,
        '--xnib-button-hover-color': this.localTheme.button.hover.backgroundColor === 'auto' ? this.localTheme.button.backgroundColor : this.localTheme.button.hover.backgroundColor,
        '--xnib-button-hover-background-color': this.localTheme.button.hover.color === 'auto' ? this.localTheme.button.color : this.localTheme.button.hover.color,
        '--xnib-button-hover-border-color': this.localTheme.button.hover.backgroundColor === 'auto' ? this.localTheme.button.backgroundColor : this.localTheme.button.hover.backgroundColor,
        '--xnib-button-hover-border-radius': this.localTheme.button.hover.borderRadius === 'auto' ? this.localTheme.borderRadius : this.localTheme.button.hover.borderRadius,
        '--xnib-button-hover-box-shadow': this.localTheme.button.hover.boxShadow === 'auto' ? this.localTheme.boxShadow : this.localTheme.button.hover.boxShadow,
        '--xnib-button-text-font-family': this.localTheme.button.fontFamily,
        '--xnib-button-text-font-weight': this.localTheme.button.fontWeight,
        '--xnib-button-text-font-style': this.localTheme.button.fontStyle,
        '--xnib-button-text-font-size': this.localTheme.button.fontSize,
        '--xnib-button-text-font-smoothing': this.localTheme.button.fontSmoothing,
        '--xnib-button-text-font-stretch': this.localTheme.button.fontStretch,
        '--xnib-button-text-color': this.localTheme.button.backgroundColor,
        '--xnib-button-text-letter-spacing': this.localTheme.button.letterSpacing,
        '--xnib-button-text-text-transform': this.localTheme.button.textTransform
      }
    },
    gradientStartColor () {
      /* New button, no gradient */
      if (this.localTheme?.dwellTimePopup?.logo?.color && this.localTheme?.dwellTimePopup?.logo?.color !== 'auto') {
        return this.localTheme?.dwellTimePopup?.logo.color
      }
      /* Legacy button gradient */
      return this.localTheme?.dwellTimePopup?.logoGradientStart || '#4276C5'
    },
    gradientEndColor () {
      /* New button, no gradient */
      if (this.localTheme?.dwellTimePopup?.logo?.color && this.localTheme?.dwellTimePopup?.logo?.color !== 'auto') {
        return this.localTheme?.dwellTimePopup?.logo?.color
      }
      /* Legacy button gradient */
      return this.localTheme?.dwellTimePopup?.logoGradientEnd || '#259BFA'
    },
    showLogo () {
      if (this.localTheme?.dwellTimePopup?.logo?.show === 'false') {
        return false
      }
      // Legacy logic
      return true
    }
  },
  mounted () {
    // New theme configurator, I'm updating the theme button with the one defined in dwellTimePopup
    // I'm merging, not replacing, to account for any missing data, just in case
    this.localTheme = { ...this.theme }
    if (this.localTheme.dwellTimePopup?.button) {
      // first replace the hover button state with the default button state for empty values
      let tempHoverButton = { ...this.localTheme.dwellTimePopup.button }
      tempHoverButton = Utils.deepMerge(tempHoverButton, this.localTheme.dwellTimePopup.button.hover)
      this.localTheme.dwellTimePopup.button.hover = { ...tempHoverButton }
      // then merge the button with the dwellTimePopup button
      this.localTheme.button = Utils.deepMerge(this.localTheme.button, this.localTheme.dwellTimePopup.button)
    }
    // Move the nibble-button element to the end of the document body in the DOM tree
    this.$parent.moveToBottomOfDOM()
    setTimeout(() => {
      this.visible = true
    }, 50)
  },
  methods: {
    primaryClick () {
      this.$emit('primaryButtonClick')
      this.closeDwellPopup()
    },
    secondaryClick () {
      this.$emit('secondaryButtonClick')
      this.closeDwellPopup()
    },
    closeDwellPopup () {
      this.visible = false
      this.$emit('exit')
    },
    mainButtonClick () {
      this.$emit('buttonClick')
      this.closeDwellPopup()
    }
  }
}
</script>

<style lang="scss">
.xnib-dwell-container, .xnib-dwell-container * {
  z-index: 2147483647;
}

.xnib-dwell-container {
  display: block;
  position: fixed;
  pointer-events: none;
  top: 0;
  right: 0;
  transform: translate(297.5px, calc(50vh - 50%));
  width: 297.5px;
  padding: 16px 0px 52.5px 0px;
  transition: transform 1.75s;

  .xnib-info-button-primary,
  .xnib-info-button-secondary {
    display: block;
    width: 100%;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-sizing: border-box;
    /* Customizable styling */
    background-color: var(--xnib-button-background-color);
    border-width: 2px;
    border-style: solid;
    border-color: var(--xnib-button-border-color);
    border-radius: var(--xnib-button-border-radius);
    box-shadow: var(--xnib-button-box-shadow);
    height: var(--xnib-button-height);
    margin: 8px 0 0 0;
    padding: var(--xnib-button-padding);

    span.text {
      /* Customizable styling */
      font-family: var(--xnib-button-text-font-family);
      font-weight: var(--xnib-button-text-font-weight);
      font-style: var(--xnib-button-text-font-style);
      font-size: var(--xnib-button-text-font-size);
      color: var(--xnib-button-text-color);
      -webkit-font-smoothing: var(--xnib-button-text-font-smoothing); /* macOS only */
      font-stretch: var(--xnib-button-text-font-stretch);
      letter-spacing: var(--xnib-button-text-letter-spacing);
      text-transform: var(--xnib-button-text-text-transform);
      display: flex;
      align-items: baseline;
      justify-content: center;
    }
    &:hover {
      /* Customizable styling */
      background-color: var(--xnib-button-hover-background-color);
      border-color: var(--xnib-button-hover-border-color);
      border-radius: var(--xnib-button-hover-border-radius);
      box-shadow: var(--xnib-button-hover-box-shadow);
    }

    &:hover span.text {
      /* Customizable styling */
      color: var(--xnib-button-hover-color);
    }
  }
  .xnib-info-button-secondary {
    margin-top: 8px;
  }
}
.xnib-dwell-container.show {
  transform: translate(0px, calc(50vh - 50%));
}
.xnib-dwell-container .xnib-dwell-popup-body {
  position: relative;
  box-sizing: border-box;
  width: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 16px;
  background: #FFFFFF;
  box-shadow: 0px 8px 16px rgba(31, 38, 46, 0.1);
  border-radius: 16px;
  opacity: 0;
  transform: scale(0);
  // transition: opacity 0.5s ease-in-out 1.5s, transform 0.75s cubic-bezier(0, 1.05, 0.95, 1) 1.5s;
  transition: opacity 0.5s ease-in-out 1.5s, transform 0.75s cubic-bezier(.34,.8,.51,1.24) 1.5s;

}

.xnib-dwell-container.show .xnib-dwell-popup-body {
  opacity: 1;
  transform: scale(1);
}

.xnib-dwell-popup {
  position: relative;
  display: inline-block;
  pointer-events: auto;
}

.xnib-dwell-logo {
  position: absolute;
  bottom: -52.5px;
  right: -65px;
  width: 72px;
  height: 72px;
}

.xnib-dwell-white-label .xnib-dwell-logo {
  opacity: 0;
}

.xnib-dwell-close {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: -16px;
  right: -16px;
  width: 32px;
  height: 32px;
  background-color: white;
  border: 1px solid #C2CCD6;
  border-radius: 100%;
  cursor: pointer; }
  .xnib-dwell-close svg {
    width: 15px;
    height: 15px; }
    .xnib-dwell-close svg path {
      transition: fill 0.2s; }
    .xnib-dwell-close:hover svg path {
      fill: #1976D2;
}

.xnib-dwell-text-container {
  font-family: Roboto, sans-serif; }
  .xnib-dwell-text-container h3 {
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    color: #1F262E;
    margin: 0; }
  .xnib-dwell-text-container p {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #3D4D5C;
}

/* xnib-container overrides */
.xnib-dwell-button-wrapper {
  margin: 0px !important;
  padding: 0px !important;
  width: 100% !important;
  min-width: auto !important;
  max-width: auto !important; }
  .xnib-dwell-button-wrapper button {
    margin: 0px !important;
    padding: 12px 15px !important;
    border-radius: 4px !important; }
    .xnib-dwell-button-wrapper button:hover {
      border-radius: 4px !important;
}
</style>
